<template>
  <div class="p-6 h-full">
    <div class="mb-4">
      <h3 class="text-2xl font-bold text-left">Infodatek HQ 2.0</h3>
    </div>
    <div class="relative h-[calc(100%-4rem)] w-full">
      <!-- Show loading state -->
      <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center bg-gray-900/50 rounded-lg">
        <p class="text-xl text-gray-300">Loading camera feed...</p>
      </div>

      <!-- Show error state with details -->
      <div v-else-if="hasError" class="absolute inset-0 flex flex-col gap-2 items-center justify-center bg-gray-900/50 rounded-lg">
        <p class="text-xl text-red-400">Camera feed niet beschikbaar</p>
        <p v-if="errorDetails" class="text-sm text-red-300">{{ errorDetails }}</p>
      </div>

      <!-- Show stream when available -->
      <div v-else class="w-full h-full">
        <img
          ref="streamRef"
          class="w-full h-full object-cover rounded-lg"
          :src="streamUrl"
          alt="Construction Camera"
          @error="handleStreamError"
          @load="handleStreamReady"
          :key="streamKey"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const isLoading = ref(true)
const hasError = ref(false)
const errorDetails = ref('')
const streamRef = ref(null)
const streamKey = ref(0)
const streamUrl = ref('https://construction-camera-stream.infodatek.com/stream.mjpg')

// Interval references for cleanup
let streamPollInterval = null
let memoryManagementInterval = null

const handleStreamError = () => {
  console.error('Stream error occurred')
  hasError.value = true
  isLoading.value = false
  errorDetails.value = 'Stream connection failed'
  emit('streamError')

  // Attempt to recover
  setTimeout(refreshStream, 5000)
}

const handleStreamReady = () => {
  console.log('Stream ready')
  isLoading.value = false
  emit('streamReady')
}

const emit = defineEmits(['streamError', 'streamReady'])

// Function to refresh the stream by forcing a new image load
const refreshStream = () => {
  streamKey.value++ // Force Vue to reload the image
}

// Memory management function
const manageMemory = () => {
  if (document.hidden) {
    // If tab is not visible, pause the stream by setting src to empty
    if (streamRef.value) {
      streamRef.value.src = ''
      hasError.value = false
      isLoading.value = true
    }
  } else {
    // If tab becomes visible again, resume the stream
    if (streamRef.value && !streamRef.value.src) {
      streamRef.value.src = streamUrl.value
    }
  }
}

// Function to check if stream is available using the readyz endpoint
const checkStreamAvailability = async () => {
  try {
    isLoading.value = true
    hasError.value = false
    errorDetails.value = ''

    const response = await fetch('https://construction-camera-stream.infodatek.com/readyz')
    const data = await response.json()

    if (response.status !== 200) {
      throw new Error(data.error || 'Stream service not ready')
    }

    if (data.status !== 'ready') {
      const issues = Object.entries(data.checks)
        .filter(([_, status]) => status !== 'operational' && status !== 'writable')
        .map(([component, status]) => `${component}: ${status}`)
        .join(', ')

      throw new Error(`System not ready: ${issues}`)
    }

    isLoading.value = false
    emit('streamReady')
  } catch (error) {
    console.error('Stream availability check failed:', error)
    hasError.value = true
    errorDetails.value = error.message
    emit('streamError')
  } finally {
    isLoading.value = false
  }
}

// Start all monitoring processes
const startMonitoring = () => {
  // Check stream availability periodically
  streamPollInterval = setInterval(() => {
    if (hasError.value) {
      checkStreamAvailability()
    }
  }, 30000)

  // Set up memory management
  memoryManagementInterval = setInterval(refreshStream, 300000) // Refresh every 5 minutes

  // Listen for visibility changes
  document.addEventListener('visibilitychange', manageMemory)
}

onMounted(() => {
  checkStreamAvailability()
  startMonitoring()
})

// Clean up
onBeforeUnmount(() => {
  if (streamPollInterval) clearInterval(streamPollInterval)
  if (memoryManagementInterval) clearInterval(memoryManagementInterval)
  document.removeEventListener('visibilitychange', manageMemory)
})
</script>
